import { api } from "@/apps/common/api-client";
import AsyncSelectWidget from "@/apps/common/components/form-widgets/AsyncSelectWidget";
import Form from "@/modules/react-jsonschema-form-bootstrap";
import validator from "@rjsf/validator-ajv8";
import FiltersBarInlineFormTemplate from "./filtersBarInlineFormTemplate";

const schema = {
  type: "object",
  properties: {
    // query: {
    //   type: "string",
    //   title: "Search"
    // },
    admin_follow_up: {
      type: "boolean",
      title: "Users with Admin Notes to follow up",
      default: false
    },
    referrals: {
      type: "boolean",
      title: "Show Member Referrals",
      default: true
    }
    // serviceType: {
    //   type: "number",
    //   title: "Service Type"
    // },
    // status: {
    //   title: "Status",
    //   type: "array",
    //   minItems: 1,
    //   items: {
    //     type: "string",
    //     enum: [
    //       APPOINTMENT_STATUS.PENDING,
    //       APPOINTMENT_STATUS.CONFIRMED,
    //       APPOINTMENT_STATUS.DUE,
    //       APPOINTMENT_STATUS.COMPLETED
    //     ]
    //   },
    //   uniqueItems: true
    // }
  }
};

const uiSchema = {
  "ui:submitButtonOptions": {
    norender: true
  },
  "ui:ObjectFieldTemplate": (props: any) => (
    <FiltersBarInlineFormTemplate {...props} />
  ),

  //   query: {
  //     "ui:gridColumnProps": { md: 12, lg: 5 },
  //     "ui:options": {
  //       placeholder: "Name, email, or phone number"
  //     }
  //   },
  //   serviceType: {
  //     "ui:widget": AsyncSelectWidget,
  //     "ui:gridColumnProps": { md: 12, lg: 7 },
  //     "ui:options": {
  //       placeholder: ""
  //     },
  //     requestCacheKey: "serviceTypes",
  //     request: () =>
  //       api.common.fetchServiceTypes().then((states) =>
  //         states.map((state) => ({
  //           label: state.name,
  //           value: state.id
  //         }))
  //       )
  //   },
  //   status: {
  //     "ui:widget": "checkboxes",
  //     "ui:options": {
  //       inline: true
  //     },
  // admin_follow_up: {
  //   "ui:widget": "checkbox",
  //   // "ui:options": {
  //   //   inline: true
  //   // }
  // },
  // referrals: {
  //   "ui:widget": "checkbox",
  //   // "ui:options": {
  //   //   inline: true
  //   // }
  // }

};

export default function FiltersBar({
  values,
  onChange
}: {
  values: any;
  onChange: any | null;
}) {
  return (
    <Form
      className="filters-bar"
      schema={schema as any}
      uiSchema={uiSchema}
      validator={validator}
      formData={values}
      onChange={(event) => {
        onChange(event.formData);
      }}
    />
  );
}
